import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from 'html-react-parser';
import {BannerAnchor} from "../loginTypes";

export interface Props {
    name?:string;
    text: string;
    infoText?: string;
    severity: string;
    onClose?: any;
    anchor?: BannerAnchor;
}
  
export class Banner extends React.Component<Props, {}> {

    componentDidMount(): void {
        let liveRegion:HTMLElement = document.getElementById("bannerAlertLiveSection") as HTMLElement;
        let child:HTMLParagraphElement = document.createElement("p");
        if(this.props.anchor){
            child.innerHTML = "alert" + this.props.text + this.props.anchor.text;
        } else {
            child.innerHTML = "alert" + this.props.text;
        }
        if(this.props.infoText){
            child.innerHTML = child.innerHTML + this.props.infoText;
        }
        setTimeout(() => { liveRegion.appendChild(child) }, 1000);
        setTimeout(() => { liveRegion.removeChild(child) }, 3000);
    }

    render() {
        let classNameStr = "banner " + this.props.severity;
        let text = this.props.text;
        if(this.props.anchor){
            let anchorElement = `<a href=${this.props.anchor.url}>${this.props.anchor.text}</a>`;
            text = String(text + anchorElement);
        }


        return(
        <React.Fragment>
            <section className={classNameStr}>
                {this.props.severity === "warning" ? <FontAwesomeIcon className={"bannerIcon"} icon={"exclamation-circle"} /> : <FontAwesomeIcon className={"bannerIcon"} icon={"check"} />}
                <p><span className={"visually-hidden"}>alert</span>{parse(text)}</p>
                <button className={"transparentButton right"} aria-labelledby={"bannerCloseButton"} onClick={this.props.onClose}>
                    <FontAwesomeIcon aria-hidden="true" icon={"times-circle"} />
                    <span id={"bannerCloseButton"} className={"visually-hidden"}>Close Alert</span>
                </button>
            </section>
            { this.props.infoText !== undefined && this.props.infoText.length > 0 ?
                <section className="infoText">
                    <p>{this.props.infoText ? parse(this.props.infoText) : ""}</p>
                </section> :
                null
            }
        </React.Fragment>
        );
    }
}