import React from "react";
import { ReactComponent as GlobeImg } from '../Assets/Images/bg_globe.svg';
import { ReactComponent as LogoSmImg } from '../Assets/Images/logo-horz-min.svg';
import { ReactComponent as LogoMdImg } from '../Assets/Images/logo_horz.svg';
import { ReactComponent as LogoImg } from '../Assets/Images/NFCU_Logo.svg';
import { ReactComponent as LocImg } from '../Assets/Images/icon-locations.svg';
import { ReactComponent as ContactImg } from '../Assets/Images/icon-contact.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
    setLoading: any;
    toggleMobileMenu: any;
    menuOpen: boolean;
}

export interface State {
    showMenu: boolean;
    currentIcon: any;
}

export class NFCUHeader extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            showMenu: false,
            currentIcon: ""
        };

        this.navigateToNFCUHome = this.navigateToNFCUHome.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.state.showMenu && !this.props.menuOpen){
            this.setState({ showMenu:false });
        }
    }

    navigateToNFCUHome() {
        window.location.href = 'https://www.navyfederal.org/';
    }

    toggleMenu() {
        let menuState = this.state.showMenu;
        let newState = !menuState;
        this.props.toggleMobileMenu(newState);
        this.setState({ showMenu: newState });
    }

    render() {
        return(
            <section>
                <section className="smallHeaderContainer">
                    <section>
                        <button className={"transparentButton mobileToggleButton"} aria-expanded={this.state.showMenu} aria-labelledby={"smMenuButton"} onClick={this.toggleMenu}>
                            <i className={"MobileMenuToggleButton"} onClick={this.toggleMenu}>
                                {this.state.showMenu ?
                                    <FontAwesomeIcon icon={"times"} aria-hidden={true} />
                                    :
                                    <FontAwesomeIcon icon={"bars"} aria-hidden={true} />
                                }
                            </i>
                            <span id="smMenuButton" className={"visually-hidden"} tabIndex={-1}>menu</span>
                        </button>
                    </section>
                    <section>
                        <button role={"link"} className={"NFCU-Logo transparentButton"} aria-labelledby={"smLogoDesc"} onClick={this.navigateToNFCUHome}>
                            <span id={"smLogoDesc"} className={"visually-hidden"}>Navy Federal Credit Union logo, home page</span>
                            <LogoSmImg pointerEvents={"all"} cursor={"pointer"} focusable={false}  title="" />
                        </button>
                    </section>
                </section>
                <section className="medHeaderContainer">
                    <section>
                        <button className={"transparentButton mobileToggleButton"} aria-expanded={this.state.showMenu} aria-labelledby={"medMenuButton"} onClick={this.toggleMenu}>
                            <i className={"MobileMenuToggleButton"} onClick={this.toggleMenu}>
                                {this.state.showMenu ?
                                    <FontAwesomeIcon icon={"times"} aria-hidden={true} />
                                    :
                                    <FontAwesomeIcon icon={"bars"} aria-hidden={true} />
                                }
                            </i>
                            <span id="medMenuButton" className={"visually-hidden"} tabIndex={-1}>menu</span>
                        </button>
                    </section>
                    <section>
                        <button role={"link"} className={"NFCU-Logo transparentButton"} aria-labelledby={"medLogoDesc"} onClick={this.navigateToNFCUHome}>
                            <LogoMdImg pointerEvents={"all"} cursor={"pointer"} focusable={false}  title="" height={32} width={300}  />
                            <span id={"medLogoDesc"} className={"visually-hidden"}>Navy Federal Credit Union logo, home page</span>
                        </button>
                    </section>
                </section>
                <section className="headerBackground">
                    <section className="headerContainer">
                        <section className="headerImages">
                            <button role={"link"} className={"NFCU-Logo transparentButton"} aria-labelledby={"desktopLogoDesc"} onClick={this.navigateToNFCUHome}>
                                <span className={"visually-hidden"} id={"desktopLogoDesc"}>Navy Federal Credit Union logo, home page</span>
                                <LogoImg pointerEvents={"all"} cursor={"pointer"} focusable={false}  title="Logo Image" />
                            </button>
                            <GlobeImg className={"bg_globe"} pointerEvents={"none"} focusable={false} title={""} />
                        </section>
                    <ul className="headerNav" role="none">
                            <li className="ContactUs">
                                <a href="https://www.navyfederal.org/contact-us/" rel='noopener noreferrer'>
                                    <ContactImg focusable={false} aria-hidden={true} />
                                    <p>Contact Us</p>
                                </a>
                            </li>
                            <li className="Locations">
                                <a href="https://www.navyfederal.org/branches-atms/index.php" rel='noopener noreferrer'>
                                    <LocImg focusable={false} aria-hidden={true} />
                                    <p>Locations</p>
                                </a>
                            </li>
                            <li>
                                <p className="RoutingNum">Routing Number:<span className="rtNum">  256074974</span></p>
                            </li>
                        </ul>
                    </section>
                </section>
            </section>
        );
    }

}