import React from 'react';
import { ReactComponent as ToolTipIcon } from "../Assets/Images/toolTip.svg";

export interface Props {
    content: string;
    label: string;
    text?: string;
    icon?: boolean;
}

export class ToolTip extends React.Component<Props, {}> {
    constructor(props: Props | Readonly<Props>){
        super(props);
        this.doNothing = this.doNothing.bind(this);
    }

    doNothing(e: { preventDefault: () => void; }) {
        e.preventDefault();
    }

    render() {
        return(
            <section className="contentText textLink">
                <button onClick={this.doNothing} aria-label={this.props.label} aria-describedby={"tooltip-desc"}>
                    {this.props.icon ?
                        <section className="icon">
                            <ToolTipIcon title={""} focusable={false} />
                        </section>
                        : null}
                    <section className="text">{this.props.text}</section>
                </button>
                <section role="tooltip" id="tooltip-desc">{this.props.content}</section>
            </section>
        )
    }
}
