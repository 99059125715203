
export function setPageName() {
    (window as any).digitalData.page.pageInfo.pageName = "nfo:digitallogin:" + getPageName(window.location.pathname);
}

function getPageName(pathName:any) {
    if (pathName.match("[/]?[a-z-]+([/][a-z-]+)?")) {
        if(pathName.startsWith("/")) {
            pathName = pathName.substring(1, pathName.length);
        }
        return pathName.replace("/^|$/g","").replace("//g",":");
    }
    return "signin";
}