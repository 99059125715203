import { ErrorBannerAttributes } from "./loginTypes";

export const timeoutMinutes = 1;
export const sessionTime = 600;

export const timeoutModalTitle = "Still Working?";
export const timeoutModalText = "You've been inactive for a while. For your security, this session will end in :time: seconds and you'll lose any information you've entered";

export const signoff_timeout = '1';
export const signoff_logoff = '2';

export const usernameToolTip = "Sign into online banking with your unique Username, which may be your access number.";

export const becomeAMemberLearnMore = "https://www.navyfederal.org/membership/become-a-member/";

export const genericTechnicalErrorMsg = "We experienced a technical problem. Please try again.";
export const genericErrorMsg = "We are unable to process your application at this time.";

export const exitModalTitle = "Cancel Sign In";
export const exitModalText = "Are you sure you want to exit? You'll lose any information you've entered.";
export const confirmExitText = "End Session";
export const rejectExitText = "Keep Working";

//ERROR BANNERS
export const Banners:Array<ErrorBannerAttributes> = [
    { name: "INVALID_LOGIN", text: "The username or password you entered was incorrect. Please try again.", infoText: "", severity: "warning" },
    { name: "USER_LOCKED_OUT", text: "You've exceeded the maximum number of sign in attempts and will need to ", infoText: "", severity: "warning" },
    { name: "UNSERNAME_LOCK_OUT", text: "You've exceeded the maximum number of attempts to enter this information. Please call us at 1-888-842-6328.", infoText: "", severity: "warning"},
    { name: "TWOFA_LOCK_OUT", text: "You've exceeded the maximum number of attempts to complete verification. Please call us at 1-888-842-6328 for assistance.", infoText: "", severity: "warning" },
    { name: "NFCU_SIGN_OFF_1", text: "To protect your security, your session has timed out due to inactivity", infoText: "", severity:"warning" },
    { name: "NFCU_SIGN_OFF_2", text: "You're now signed out.", infoText: "", severity: "warning"},
    { name: "ANE_ERROR_BANNER", text: "The information you provided does not match our records.", infoText: "For further assistance, please contact Navy Federal toll-free in the U.S. at 1-888-868-8123.For toll-free numbers when overseas, visit <a href=\"https://www.navyfederal.org/overseas\">navyfederal.org/overseas</a>. Use 1-703-255-8837 for collect international calls.", severity: "warning" },
    { name: "RESET_PAGE_WELCOME_BANNER", text: "You’ve successfully confirmed your identity.", infoText: "", severity: "success"},
    { name: "PASSWORD_CHANGE_COMPLETE", text: "You've successfully reset your password.", infoText: "", severity: "success" },
    { name: "RSA_LOCKOUT", text: "We’re unable to verify your identity at this time, and sign in is temporarily blocked. Please call 1-888-842-6328 to resolve this issue.", infoText: "", severity: "warning" },
    { name: "TECH_ERROR", text: "We experienced a technical problem. Please try again.", infoText: "", severity: "warning" },
    { name: "GENERIC_ERROR", text: "We are unable to process your application at this time.", infoText: "", severity: "warning" },
];