import * as React from "react";
import { cancel } from '../Utils';
import axiosInstance from "../api";

export interface Props {
    id?: string;
    orangeId?: string;
    orangeOption?: string;
    grayOption?: string;
    continueDisabled?: any;
    onCancel?: Function;
    history?: any;
    setLoading: Function;
    hasFocus?: boolean;
    reCaptchaEnabled?: boolean;
}

export interface State {
    reCaptchaSiteKey: string;
}

export class SubmitButtonBar extends React.Component<Props, State> {

    private axios = axiosInstance;
    //private recaptchaInstance: any;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            reCaptchaSiteKey: ""
        };
        
        this.onCancel = this.onCancel.bind(this);
        this.getRecaptchaSiteKey = this.getRecaptchaSiteKey.bind(this);
        this.executeCaptcha = this.executeCaptcha.bind(this);
        this.verifyRecaptcha = this.verifyRecaptcha.bind(this);
    }

    componentDidMount(): void {
        this.getRecaptchaSiteKey();
    }

    onCancel() {
        if(this.props.onCancel) {
            this.props.onCancel();
        }
        else{
            if(this.props.setLoading){
                this.props.setLoading(true);
            }
            cancel();
        }
        return false;
    }

    getRecaptchaSiteKey(){
        if(!this.props.reCaptchaEnabled){
            return;
        }
        this.axios.get('/utils/sitekey')
            .then(response =>{
                if(response != null){
                    this.setState({
                        reCaptchaSiteKey: response.data
                    });
                }
            })
            .catch()

    }

    executeCaptcha() {
        if(!this.props.reCaptchaEnabled){
            return;
        }
       // this.recaptchaInstance.execute();
    }

    verifyRecaptcha(token:string){
        console.log(token);
        if(!this.props.reCaptchaEnabled){
            return;
        }

        let form:HTMLFormElement = document.getElementById("loginForm")! as HTMLFormElement;
        this.axios.post("/utils/verify", token)
            .then(response => {
                if(response.data){
                    form.submit();
                } else{
                    this.props.continueDisabled(true);
                }
            })
            .catch(error => {
                this.props.continueDisabled(true);
            });
    }

    render() {
        let continueClassName = "button button-orange";
        if (this.props.continueDisabled) {
            continueClassName = continueClassName + " button-disabled";
        }

        let orangeOption:any = "";
        if(this.props.orangeOption){
            if(this.props.hasFocus) {
                orangeOption = <button id={this.props.orangeId} className={continueClassName} autoFocus type="submit" aria-disabled={this.props.continueDisabled} disabled={this.props.continueDisabled}>{this.props.orangeOption}</button>;
            }
            else {
                orangeOption = <button id={this.props.orangeId} className={continueClassName} type="submit" aria-disabled={this.props.continueDisabled} disabled={this.props.continueDisabled}>{this.props.orangeOption}</button>
            }
        }

        let grayOption:any = "";
        if(this.props.grayOption) {
            if(!this.props.orangeOption && this.props.hasFocus) {
                grayOption = <button className="button button-gray" type="button" autoFocus onClick={this.onCancel}>{this.props.grayOption}</button>
            }
            else {
                grayOption = <button className="button button-gray" type="button" onClick={this.onCancel}>{this.props.grayOption}</button>
            }
        }

        return(
            <React.Fragment>
                {this.props.reCaptchaEnabled ?
                <section id={"recaptcha"} />
                : null}
            <section id={this.props.id} className="buttonBar">
                { orangeOption }
                { grayOption }
            </section>
            </React.Fragment>
        );
    }
}