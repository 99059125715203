import axios from 'axios';

let appBaseUrl;
let appContextPath;

let regex =  new RegExp("^(http[s]?://[^/]+([/][^/]+))[/].*$|^(http[s]?://[^/]+)[/]?$", "g");
let resArray = regex.exec(window.location.href);
if(resArray) {
    appBaseUrl = resArray[1]?resArray[1]:resArray[3];
    appContextPath = resArray[2]?resArray[2]:"/"
}

const xsrfToken = document.querySelector("meta[name='csrfToken']").getAttribute("content");

let axiosInstance=axios.create();
axiosInstance.defaults.baseURL = appBaseUrl;
axiosInstance.defaults.headers.post['X-CSRF-TOKEN'] = xsrfToken;
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.get['Cache-Control'] = 'no-cache';
axiosInstance.defaults.headers.get['Pragma'] = 'no-cache';
axiosInstance.interceptors.request.use(function (config) {
    var pushInProgress = sessionStorage.getItem("verifyPushInProgress");
    if (pushInProgress !== "true") {
        sessionStorage.setItem("sessionTimeRemainingReset", "true");
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
export default axiosInstance;

export var contextPath = appContextPath;
export var baseURL = appBaseUrl;