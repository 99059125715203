import React from "react";
import { logout } from "../Utils";
import * as Constants from "../Constants";

export interface Props {
    startingTime: number;
    onCompletion: any;
    timeRemainingTag: string;
    hidden?: boolean;
    history?: any;
    onWarning?: any;
    warningMinutes?: number;
    setLoading: Function;
    tick?: Function;
    timerReset?: boolean;
    resetParent?: Function;
}

export interface State {
    timeRemaining: number;
    timerActive: boolean;
    timerReset: boolean;
}

export class Timer extends React.Component<Props, State> {
    interval: NodeJS.Timeout | undefined;
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            timeRemaining: props.startingTime,
            timerActive: false,
            timerReset: false,
        };

        this.tick = this.tick.bind(this);
    }

    tick() {
        let timerReset = sessionStorage.getItem(this.props.timeRemainingTag + "Reset");
        if (timerReset !== null && timerReset === "true") {
            clearTimeout(this.interval);
            this.interval = undefined;
            this.setState({
                timeRemaining: this.props.startingTime,
                timerActive: true
            });
            if(this.props.timerReset != null && this.props.timerReset && this.props.resetParent) {
                this.setState({
                    timeRemaining: this.props.startingTime,
                    timerActive: true,
                    timerReset: false
                });
                this.props.resetParent();
            }
            if(this.props.tick) {
                this.props.tick(this.props.startingTime);
                this.interval = setTimeout(() => this.tick(), 1000);
                sessionStorage.removeItem(this.props.timeRemainingTag + "Reset");
            }
        } else
        if (this.state.timeRemaining > 0 && sessionStorage.getItem(this.props.timeRemainingTag) !== "0") {
            if (this.props.onWarning && this.props.warningMinutes && this.state.timeRemaining === (this.props.warningMinutes * 60)) {
                this.props.onWarning();
            }
            let newTimeRemaining = this.state.timeRemaining - 1;
            if(this.props.tick) {
                this.props.tick(newTimeRemaining);
            }
            this.setState({ timeRemaining: newTimeRemaining });
            sessionStorage.setItem(this.props.timeRemainingTag, newTimeRemaining.toString());
            this.interval = setTimeout(() => this.tick(), 1000);
        } else {
            sessionStorage.setItem(this.props.timeRemainingTag, "0");
            if (this.state.timerActive) {
                this.props.onCompletion();
            }
            this.setState({
                timerActive: false
            });
            if (this.props.timeRemainingTag === "sessionTimeRemaining") {
                logout(Constants.signoff_timeout);
            }
        }
    }

    componentDidMount() {
        let timeRemaining = sessionStorage.getItem(this.props.timeRemainingTag);
        if (timeRemaining !== null && timeRemaining === "0") {
            this.props.onCompletion();
            this.setState({
                timeRemaining: 0,
                timerActive: false
            });
        } else {
            if (timeRemaining !== null) {
                let timeRemainingSeconds = parseInt(timeRemaining);
                this.setState({
                    timeRemaining: timeRemainingSeconds,
                    timerActive: true
                });
            } else {
                this.setState({
                    timerActive: true
                });
            }
            this.interval = setTimeout(() => this.tick(), 1000);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        /*if(this.state.timeRemaining === 0 && this.interval instanceof NodeJS.Timeout){
            clearInterval(this.interval);
        }*/
    }

    componentWillUnmount() {
        if (this.interval !== undefined) {
            clearInterval(this.interval);
        }
    }

    render() {
        if (this.props.hidden) {
            return null;
        } else {
            return(
                <div className="timer contentText">
                    {this.state.timeRemaining} seconds remaining
                </div>
            )
        }
        
    }
}