import { baseURL } from './api';
import * as Constants from "./Constants";

export let redirect = (url: string) => {
    window.location.href = url;
};

let finish = (url: string) => {
    sessionStorage.clear();
    window.location.href = url;
};

export let cancel = () => {
    let url = baseURL + '/cancel';
    finish(url);
};

export let failure = (params?: undefined) => {
    let url = baseURL + '/failure';
    if(params){
        url = url + failure;
    }
    finish(url);
};

export let logout = (signoff?:string) => {
    let url = baseURL + '/cancel';
    if(signoff) {
        url = url + '?NFCUSIGNOFF=' + signoff;
    }
    window.location.replace(url);
};

export function saveBannerInSession(pageName: string, text: string, infoText: string, severity: string) {
    sessionStorage.setItem(pageName + "BannerText", text);
    sessionStorage.setItem(pageName + "BannerInfoText", infoText);
    sessionStorage.setItem("severity", severity);
    sessionStorage.setItem("show" + pageName + "Banner", "true");
}

export function removeBannerFromSession(pageName: string) {
    sessionStorage.removeItem(pageName + "BannerText");
    sessionStorage.removeItem(pageName + "BannerInfoText");
    sessionStorage.removeItem("severity");
    sessionStorage.removeItem("show" + pageName + "Banner");
}

export function sanitizeBannerText(text:string):string {
    let bannerText:string = "";
    for(let banner of Constants.Banners){
        if(text === banner.text){
            bannerText = banner.text;
        }
    }
    return bannerText;
}

export function sanitizeInfoBannerText(text:string):string {
    let infoText:string = "";
    for(let banner of Constants.Banners){
        if(text === banner.infoText){
            infoText = banner.infoText;
        }
    }
    return infoText;
}

export function setAACookie() {
    var aaCookie = getCookie("aatoken");

    if (aaCookie === null) {
        // Create a new AA Cookie
        createCookie("aatoken", makeRandomValue(), 1827);
    }
}

function getCookie(name: string) {

    var dc = document.cookie,
        prefix = name + "=",
        begin = dc.indexOf("; " + prefix);

    var end:number | null = null;

    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) {
            return null;
        }
    } else {
        begin += 2;
        end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }

    return decodeURIComponent(dc.substring(begin + prefix.length, end!));
}

function createCookie(cookieName:string, cookieValue:string, nDays:number) {

    var today = new Date(),
        expire = new Date();

    if (nDays === null || nDays === 0) {
        nDays = 1;
    }

    expire.setTime(today.getTime() + 3600000 * 24 * nDays);
    document.cookie = cookieName + "=" + encodeURIComponent(cookieValue) + ";expires=" + expire.toUTCString() + ";domain=navyfederal.org;path=/";
}

function makeRandomValue() {

    var text = '',
        possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
        i;

    for (i = 0; i < 50; i++) {
        text += possible.charAt(Math.floor( cryptoRandom() * possible.length));
    }

    return text;
}

function cryptoRandom() {
    const typedArray = new Uint32Array(1);
    const randomValue = crypto.getRandomValues(typedArray)[0];
    const returnValue = randomValue / Math.pow(2, 32);
    return Math.abs(returnValue);
}


