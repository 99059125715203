import React from "react";
import { ButtonBar } from '../Components/ButtonBar';
import { TextField } from '../Components/TextField';
import { Checklist } from '../Components/Checklist';
import { ExpandableContainer } from '../Components/ExpandableContainer';
import { Modal } from '../Components/Modal';
import { Banner } from '../Components/Banner';
import axiosInstance from '../api';
import * as Constants from "../Constants";
import * as Utils from "../Utils";
import {PasswordObject, ErrorBannerAttributes, errorObj, ChecklistEntry} from "../loginTypes";
import {Timer} from "../Components/Timer";
import {logout} from "../Utils";
import * as Analytics from '../Common/Analytics';


interface FormProps {
    pageHeader?: string;
    setLoading: any;
    history?: any;
    showMenu: boolean;
}

interface FormState {
    password: PasswordObject;
    verifyPassword: PasswordObject;
    continue: boolean;
    showCancelModal: boolean;
    showTimeoutModal: boolean;
    timeoutModalText: string;
    showBanner: boolean;
    passwordVisible: boolean;
    reenterPasswordVisible: boolean;
    passwordContains: Array<ChecklistEntry>;
    resetTimer: boolean;
}

export class ResetPasswordForm extends React.Component<FormProps, FormState> {

    private axios = axiosInstance;

    constructor(props: FormProps | Readonly<FormProps>) {
        super(props);
        this.state = {
            password: { value: "", showInline: false, inlineErrors: [""] },
            verifyPassword: { value: "", showInline: false, inlineErrors: ["Your password values must match"] },
            continue: false,
            showCancelModal: false,
            showTimeoutModal: false,
            timeoutModalText:  Constants.timeoutModalText.replace(':time:', "60"),
            showBanner: false,
            passwordVisible: false,
            reenterPasswordVisible: false,
            passwordContains: [
                { id: "passwordCharReq", className: "checklist-disabled", text: "\tmust contain 8-32 characters" },
                { id: "passwordNumberReq", className: "checklist-disabled", text: "\tmust contain at least one number" },
                { id: "passwordLetterReq", className: "checklist-disabled", text: "\tmust contain at least one capital letter" },
                { id: "passwordSpecialReq", className: "checklist-disabled", text: "\tmust contain at least one special character: # $ % ' ^ ,  + . : | ? @ / ] [ _ ` { } ! ; - ~" },
                { id: "passwordSpacesReq", className: "checklist-disabled", text: "\tcan't contain blank spaces" }
            ],
            resetTimer: false,
        };

        this.checkBannersValues = this.checkBannersValues.bind(this);
        this.updateState = this.updateState.bind(this);
        this.showErrorOnBlur = this.showErrorOnBlur.bind(this);
        this.enableOnChange = this.enableOnChange.bind(this);
        this.removeDuplicateArrayEntries = this.removeDuplicateArrayEntries.bind(this);
        this.changeInlineError = this.changeInlineError.bind(this);
        this.disableAllGreenChecks = this.disableAllGreenChecks.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.toggleCancelModal = this.toggleCancelModal.bind(this);
        this.resetTimerAndToggleModal = this.resetTimerAndToggleModal.bind(this);
        this.toggleTimeoutModal = this.toggleTimeoutModal.bind(this);
        this.tick = this.tick.bind(this);
        this.onSessionTimeout = this.onSessionTimeout.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.timerResetSuccess = this.timerResetSuccess.bind(this);
    }

    componentDidMount() {
        Analytics.setPageName();
        let banner:ErrorBannerAttributes = this.checkBannersValues("RESET_PAGE_WELCOME_BANNER")!;
        Utils.saveBannerInSession('reset', banner.text!,"", banner.severity!);
        this.setState({ showBanner: true });
        window.scrollTo(0, 0);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log("error name:" + error.name + "\nerror message: " + error.message + "\nerror stack: " + error.stack);
        console.log("error component stack: " + errorInfo.componentStack);
    }

    checkBannersValues(value:string):ErrorBannerAttributes | null{
        let returnValue:ErrorBannerAttributes | null = null;
        Constants.Banners.forEach(object => {
            if(object.name === value){
                returnValue = object;
            }
        });
        return returnValue;
    }

    // Update state on change
    updateState(field: React.ReactText, value: any) {
        let stateCopy: React.ComponentState = { ...this.state };
        stateCopy[field].value = value;
        this.setState(stateCopy);
    }

    removeDuplicateArrayEntries(array:Array<string>):Array<string>{
        const changeToSet:Set<string> = new Set(array);
        return [...changeToSet];
    }

    // Updates the inline error visibility and/or text
    changeInlineError(field: string, bool: boolean, errors?: Array<string> | undefined) {
        let stateCopy: React.ComponentState = { ...this.state };
        stateCopy[field].showInline = bool;

        if (errors !== undefined) {
            stateCopy[field].inlineErrors = errors;
        }

        this.setState(stateCopy);
    }

    // Updates Continue Button state when you click out of a field
    showErrorOnBlur(e: { currentTarget: { id: any; }; }) {
        let stateCopy: React.ComponentState = { ...this.state };
        const fieldName = e.currentTarget.id;
        const fieldValue = stateCopy[fieldName].value;

        // Find errors in field entries and enable/disable continue button
        if (!this.isFieldValueValid(fieldName, fieldValue)) {
            if (stateCopy[fieldName].showInline !== undefined) {
                stateCopy[fieldName].showInline = true;
            }
        }

        this.setState(stateCopy);
    }

    // Updates Continue Button state when fields changed
    enableOnChange(e: { currentTarget: { id: any; }; }) {
        let stateCopy: React.ComponentState = { ...this.state };
        const fieldName = e.currentTarget.id;
        const fieldValue = stateCopy[fieldName].value;

        let errors = 0;
        // Find errors in field entries and enable/disable continue button
        if (!this.isFieldValueValid(fieldName, fieldValue)) {
            errors++;
        }
        for (let field in stateCopy) {
            //console.log(field);
            if ((stateCopy[field].showInline !== null && stateCopy[field].showInline)
                || (stateCopy[field].value === "")) {
                errors++;
            }
        }
        if(stateCopy["password"].value !== stateCopy["verifyPassword"].value) {
            errors++;
        }
        stateCopy["continue"] = (errors <= 0);

        this.setState(stateCopy);
    }

    isFieldValueValid(fieldName: string, fieldValue: string | any[]) {
         if (fieldName === "password") {
            let passwordValid = true;
            // Reset verifyPassword inline error
            if (fieldValue === this.state.verifyPassword.value) {
                this.changeInlineError("verifyPassword", false);
            }
            // Check min length
            if (fieldValue.length < 8 || fieldValue.length > 32) {
                passwordValid = false;
                this.disableGreenText(document.getElementById("passwordCharReq"));
            } else {
                this.enableGreenText(document.getElementById("passwordCharReq"));
            }
            // Check for at least 1 number
            if (!/\d/.test(String(fieldValue))) {
                passwordValid = false;
                this.disableGreenText(document.getElementById("passwordNumberReq"));
            } else {
                this.enableGreenText(document.getElementById("passwordNumberReq"));
            }
            // Check for at least 1 capital letter
            if (!/[A-Z]/.test(String(fieldValue))) {
                passwordValid = false;
                this.disableGreenText(document.getElementById("passwordLetterReq"));
            } else {
                this.enableGreenText(document.getElementById("passwordLetterReq"));
            }
            // Check for at least one special character
            if (!/[_();/$@#.'%+!~{}`:,|[?\]]/.test(String(fieldValue))) {
                passwordValid = false;
                this.disableGreenText(document.getElementById("passwordSpecialReq"));
            } else {
                this.enableGreenText(document.getElementById("passwordSpecialReq"));
            }
            //check for spaces
            if (!/\S/.test(String(fieldValue))) {
                passwordValid = false;
                this.disableGreenText(document.getElementById("passwordSpacesReq"));
            } else {
                this.enableGreenText(document.getElementById("passwordSpacesReq"));
            }

            return passwordValid;

        } else if (fieldName === "verifyPassword") {
            // Check if value matches password
            if (fieldValue !== this.state.password.value && this.state.password.value !== "") {
                return false;
            }
        }
        return true;
    }

    enableGreenText(listItem: HTMLElement | null) {
       for(let item of this.state.passwordContains){
           if(listItem != null && listItem.id === item.id){
               item.className = "checklist-enabled";
           }
       }
    }

    disableGreenText(listItem: HTMLElement | null) {
        for(let item of this.state.passwordContains){
            if(listItem != null && listItem.id === item.id){
                item.className = "checklist-disabled";
            }
        }
    }

    disableAllGreenChecks() {
        for(let item of this.state.passwordContains){
            item.className = "checklist-disabled"
        }
    }

    onContinue(e: { preventDefault: () => void; }) {
        e.preventDefault();
        if (!this.state.continue) {
            return;
        }
        this.closeBanner();

        this.props.setLoading(true);
        const password: string = this.state.password.value;
        this.axios.post("/password", { password: password })
            .then(response => {
                sessionStorage.setItem("sessionTimeRemainingReset", "true");
                let errorResponse:Array<errorObj> = response.data.errors;
                if (errorResponse === null || errorResponse.length === 0) {
                    this.props.setLoading(true);
                    window.location.href = "/signin/pw_change_success";
                } else {
                    let passwordErrors:Array<string> = [];
                    errorResponse.forEach((error: errorObj) => {
                        let errMsg:string = error.errMsg;

                        // Define inline errors for each condition, and update on the page
                        switch(errMsg){
                            case "password-has-PII":
                                passwordErrors.push("Your password can't contain any part of your email, SSN/Tax ID, name, DOB, account number or Access Number.");
                            break;

                            case "inappropriate-password":
                                passwordErrors.push("Your password can't contain any inappropriate words.");
                            break;

                            case "past-password":
                                passwordErrors.push("Your new password can't be the same as any of your last 5 passwords.");
                            break;

                            case "password-has-username":
                                passwordErrors.push("Your password can't contain your username.");
                            break;

                            case "password-has-dictionary-word":
                                passwordErrors.push("Your password can't contain any dictionary words.");
                            break;

                            case "password-has-invalid-character":
                                passwordErrors.push("Your password can't contain any invalid characters");
                            break;

                            default:
                                Utils.saveBannerInSession("reset", Constants.genericTechnicalErrorMsg , "", "warning");
                                this.setState({ showBanner: true });
                        }

                    });
                    if (passwordErrors.length >= 0) {
                        passwordErrors = this.removeDuplicateArrayEntries(passwordErrors);
                        this.changeInlineError("password", true, passwordErrors);
                        this.setState({
                           password: {value: "", showInline: this.state.password.showInline, inlineErrors: this.state.password.inlineErrors},
                           verifyPassword: {value: "", showInline: this.state.verifyPassword.showInline, inlineErrors: this.state.verifyPassword.inlineErrors},
                        });
                        this.disableAllGreenChecks();

                    }
                }
            })
            .catch(error => {
                this.props.setLoading(false);
                Utils.saveBannerInSession("reset", Constants.genericErrorMsg, "", "warning");
                this.setState({ showBanner: true });
            })
            .finally(() => {
                this.props.setLoading(false);
                window.scrollTo(0, 0);
            });
    }

    togglePassword(e: { currentTarget: { id: string; }; }) {
        if (e.currentTarget.id === "passwordIcon") {
            this.setState({ passwordVisible: !this.state.passwordVisible });
        }
        if (e.currentTarget.id === "verifyPasswordIcon") {
            this.setState({ reenterPasswordVisible: !this.state.reenterPasswordVisible});
        }
    }

    onCancel() {
        sessionStorage.clear();
        logout(Constants.signoff_logoff);
    }

    toggleCancelModal() {
        this.setState({ showCancelModal: !this.state.showCancelModal });
    }

    resetTimerAndToggleModal(){
        sessionStorage.setItem("sessionTimeRemainingReset", "true");
        this.setState({ resetTimer: true });
        this.toggleTimeoutModal();
    }

    timerResetSuccess(){
        this.setState({ resetTimer: true });
    }

    toggleTimeoutModal(){
        this.setState({ showTimeoutModal: !this.state.showTimeoutModal });
    }

    tick(remainingTime: number) {
        if(this.state.showTimeoutModal) {
            this.setState({timeoutModalText: Constants.timeoutModalText.replace(':time:', remainingTime+"")})
        }
    }

    onSessionTimeout() {
        this.props.setLoading(true);
        this.setState({ showTimeoutModal: false });
    }

    closeBanner() {
        Utils.removeBannerFromSession("reset");
        this.setState({ showBanner: false });
    }

    render() {

        let passwordRequirements = "<section class=\"requirementsContainer\"><section class=\"requirementsHeader\" role=\"heading\" aria-level=\"5\">Password must contain:</section><ul class=\"requirementsList\" role=\"list\"><li class=\"requirementEntry\" role=\"listitem\">between 8-32 characters</li><li class=\"requirementEntry\" role=\"listitem\">at least one number</li><li class=\"requirementEntry\" role=\"listitem\">at least one capital letter</li><li class=\"requirementEntry\" role=\"listitem\">at least one special character: # $ % ‘ ^ ,  + . : | ? @ / ] [ _ ` { } ! ; - ~</li></ul><section class=\"requirementsHeader\" role=\"heading\" aria-level=\"5\" >Password can't contain:</section><ul class=\"requirementsList\" role=\"list\"><li class=\"requirementEntry\" role=\"listitem\">blank spaces</li><li class=\"requirementEntry\" role=\"listitem\">special characters other than: # $ % ‘ ^ ,  + . : | ? @ / ] [ _ ` { } ! ; - ~</li><li class=\"requirementEntry\" role=\"listitem\">any part of your email, SSN, first or last name, DOB, account number or access number</li><li class=\"requirementEntry\" role=\"listitem\">your username</li><li class=\"requirementEntry\" role=\"listitem\">inappropriate word or phrase</li></ul></section>";

        let banner = <section />;
        let bannerText:string = "";
        if (sessionStorage.getItem("showresetBanner") === "true") {
            bannerText = sessionStorage.getItem("resetBannerText")!;
            let severity = sessionStorage.getItem("severity");
            let bannerInfoText;
            if (sessionStorage.getItem("resetBannerInfoText") !== null) {
                bannerInfoText = sessionStorage.getItem("resetBannerInfoText");
            }
            if (bannerText && severity) {
                bannerText = Utils.sanitizeBannerText(bannerText);
                if (bannerInfoText === null || bannerInfoText === undefined) {
                    banner = <Banner text={bannerText} severity={severity} onClose={this.closeBanner} />
                } else {
                    bannerInfoText = Utils.sanitizeInfoBannerText(bannerInfoText);
                    banner = <Banner text={bannerText} infoText={bannerInfoText} severity={severity} onClose={this.closeBanner} />
                }
            }
        }

        return (
            <section>
                <Modal id={"exitModal"}
                       setLoading={this.props.setLoading}
                       show={this.state.showCancelModal}
                       onDismiss={this.toggleCancelModal}
                       onAccept={this.onCancel}
                       title={Constants.exitModalTitle}
                       text={Constants.exitModalText}
                       showX={true}
                       orangeText={Constants.rejectExitText}
                       grayText={Constants.confirmExitText} />

                <Timer startingTime={Constants.sessionTime}
                       onCompletion={this.onSessionTimeout}
                       onWarning={this.toggleTimeoutModal}
                       warningMinutes={Constants.timeoutMinutes}
                       timeRemainingTag="sessionTimeRemaining"
                       hidden={true}
                       setLoading={this.props.setLoading}
                       tick={this.tick}
                       timerReset={this.state.resetTimer}
                       resetParent={this.timerResetSuccess}
                />
                <Modal id={"sessionModal"}
                       show={this.state.showTimeoutModal}
                       onDismiss={this.resetTimerAndToggleModal}
                       onAccept={this.onCancel}
                       orangeText={"Keep Working"}
                       grayText={"End Session"}
                       title={Constants.timeoutModalTitle}
                       text={this.state.timeoutModalText}
                       showX={false}
                       setLoading={this.props.setLoading}/>

                <section className="resetContainer">
                    <section className={"resetContent"}>
                        <h1>Account Recovery</h1>
                        <section className="bannerContainer">
                            {banner}
                            <section id={"bannerAlertLiveSection"} className={"visually-hidden"} aria-live={"assertive"} aria-atomic={true} />
                        </section>
                        <h2>Reset Your Password</h2>
                        <p className={"contentDescription"}>Please provide a new password and click “Submit.” Once your new password is successfully changed, you will have immediate access to your Navy Federal account.</p>
                        <form onSubmit={this.onContinue} autoComplete="off">
                            <section className="doublePanel">
                                <section className={this.props.showMenu ? "halfPanel halfPanelFull" : "halfPanel"}>
                                    <section className="textPanel">
                                        <h5>Your password:</h5>
                                        <Checklist optionsList={this.state.passwordContains} />
                                        <ExpandableContainer expandLinkText="Show Full Password Rules"
                                            collapseLinkText="Hide Full Password Rules"
                                            content={passwordRequirements} />
                                    </section>
                                </section>
                                <section className={this.props.showMenu ? "halfPanel halfPanelFull" : "halfPanel"}>
                                    <TextField id="password" label="Enter Password" guideText={""}
                                        type={this.state.passwordVisible ? "text" : "password" }
                                        value={this.state.password.value}
                                        //minLength={8}
                                        maxLength={32}
                                        allowedChars={"a-zA-Z0-9_;/$@#.'%+!~{}`:,|[?\\]\\\\"}
                                        updateState={this.updateState}
                                        inlineErrors={this.state.password.showInline ? this.state.password.inlineErrors : [""]}
                                        changeInlineError={this.changeInlineError}
                                        onBlur={this.showErrorOnBlur}
                                        enableOnChange={this.enableOnChange}
                                        passwordField={true}
                                        showPassword={this.state.passwordVisible}
                                        togglePassword={this.togglePassword}
                                        className={"enterPassword"}>
                                    </TextField>
                                    <TextField id="verifyPassword" label="Re-enter Password"
                                        type={this.state.reenterPasswordVisible ? "text" : "password" }
                                        value={this.state.verifyPassword.value}
                                        //minLength={8}
                                        maxLength={32}
                                        allowedChars={"a-zA-Z0-9_;/$@#.'%+!~{}`:,|[?\\]\\\\"}
                                        updateState={this.updateState}
                                        inlineErrors={this.state.verifyPassword.showInline ? this.state.verifyPassword.inlineErrors : [""]}
                                        changeInlineError={this.changeInlineError}
                                        onBlur={this.showErrorOnBlur}
                                        enableOnChange={this.enableOnChange}
                                        passwordField={true}
                                        showPassword={this.state.reenterPasswordVisible}
                                        togglePassword={this.togglePassword}
                                        className={"reEnterPassword"}>
                                    </TextField>
                                    <section className="horizontalLine"/>
                                    <ButtonBar className={this.props.showMenu ? "menuButtons" : "" } setLoading={this.props.setLoading} orangeOption={"Submit"} grayOption={"Cancel"} onContinue={this.onContinue}
                                        continueDisabled={!this.state.continue} onCancel={this.toggleCancelModal} />
                                </section>
                            </section>
                        </form>
                    </section>
                </section>
            </section>
        )
    }
}