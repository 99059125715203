import React from "react";
import { Route, Routes} from "react-router-dom";
import * as Constants from './Constants' ;
import { ClipLoader } from 'react-spinners';
import { NFCUHeader } from "./Components/NFCUHeader";
import { NFCUFooter } from "./Components/NFCUFooter";
import { MobileMenu } from "./Components/MobileMenu";
import { LoginPage } from "./Pages/LoginPage";
import { ResetPasswordForm } from "./Pages/ResetPasswordForm";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBars, faTimes, faExclamationCircle, faCheck,
    faTimesCircle, faLock, faEye, faEyeSlash, faAngleDoubleDown, faAngleDoubleUp
} from "@fortawesome/free-solid-svg-icons";

library.add(faBars, faTimes, faExclamationCircle, faCheck, faTimesCircle, faLock,
    faEye, faEyeSlash, faAngleDoubleDown, faAngleDoubleUp, faLock);

export interface IPageProps {
    history: any;
}

export interface IPageState {
    loading: boolean;
    showModal: boolean;
    showMenu: boolean;
    modalText: string;
    historySize: number;
    screenWidth: number;
    mobileMenuFirstClick: boolean;
}

export class AppContainer extends React.Component<IPageProps, IPageState> {
    private currentPathname = null;
    private currentSearch = null;

    constructor({props}: { props: any }) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            showMenu: false,
            modalText: Constants.timeoutModalText.replace(':time:', "60"),
            historySize: 0,
            screenWidth: window.innerWidth,
            mobileMenuFirstClick: false
        };
        this.setLoading = this.setLoading.bind(this);
        this.isLoading = this.isLoading.bind(this);
        this.onSessionTimeout = this.onSessionTimeout.bind(this);
        this.forceSessionTimeout = this.forceSessionTimeout.bind(this);
        this.showModal = this.showModal.bind(this);
        this.dismissModal = this.dismissModal.bind(this);
        this.tick = this.tick.bind(this);
        this.showHideMenu = this.showHideMenu.bind(this);
        this.screenWidth = this.screenWidth.bind(this);
    }

    componentDidMount() {
        sessionStorage.setItem("sessionTimeRemaining", (Constants.sessionTime).toString());

        const history = this.props.history;
        history.listen((newLocation: { pathname: null; search: null; }, action: string) => {
            if(action === "PUSH") {
                if(
                    newLocation.pathname !== this.currentPathname ||
                    newLocation.search !== this.currentSearch
                ) {
                    this.currentPathname = newLocation.pathname;
                    this.currentSearch = newLocation.search;

                    history.push({
                        pathname: newLocation.pathname,
                        search: newLocation.search
                    });
                }
            } else {
                history.go(1);
            }
        });

        window.addEventListener("resize", (e:UIEvent) => this.screenWidth(e));

        let pageContainerElement = document.getElementsByClassName("pageContainer")[0];
        pageContainerElement.setAttribute("class","pageContainer slideIn");
        pageContainerElement.setAttribute("style","position:relative");
    }

    componentDidUpdate(prevProps: Readonly<IPageProps>, prevState: Readonly<IPageState>, snapshot?: any): void {
        if(prevState.screenWidth !== this.state.screenWidth ){
            this.setState({ screenWidth: window.innerWidth });
        }

        let docWidth = document.documentElement.offsetWidth;

        [].forEach.call(
            document.querySelectorAll('*'),
            function(el:HTMLElement) {
                if (el.offsetWidth > docWidth) {
                    //console.log(el);
                }
            }
        );
    }

    shouldComponentUpdate(nextProps: Readonly<IPageProps>, nextState: Readonly<IPageState>, nextContext: any): boolean {
        let result:boolean = true;

        if(nextContext.screenWidth === null || nextState.screenWidth < 375 || nextState.screenWidth > 5120){
            result = false;
        }

        return result;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.screenWidth);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log(error);
        console.log(errorInfo);
    }

    // Function to set app state as loading -> show spinner & disable background
    setLoading(loading:boolean) {
        this.setState({loading: loading});
    }

    isLoading() {
        return this.state.loading;
    }

    onSessionTimeout() {
        this.setState({showModal: false, loading: true});
    }

    forceSessionTimeout() {
        this.setState({showModal: false, loading: true});
    }

    showModal() {
        this.setState({showModal: true});
    }

    dismissModal() {
        sessionStorage.setItem("sessionTimeRemainingReset", "true");
        this.setState({showModal: false});
    }

    tick(remainingTime: number) {
        if(this.state.showModal) {
            this.setState({modalText: Constants.timeoutModalText.replace(':time:', remainingTime+"")})
        }
    }

    getUrls() {

    }

    showHideMenu(value: any) {
        if(value === true && !this.state.mobileMenuFirstClick){
            this.setState({ mobileMenuFirstClick: true });
        }
        this.setState({ showMenu: value });
    }

    screenWidth(e:UIEvent){
        if(e != null){
            this.setState({ screenWidth: window.innerWidth });
        }
    }

    render() {
        let menu = <section />;
        let containerClassName = String("MobileMenuContainer");
        if (this.state.showMenu && this.state.screenWidth <= 779 ) {
            containerClassName = String("MobileMenuContainer MobMenuDisplayed");
            menu = <MobileMenu visible={this.state.showMenu} />;
        } else {
            menu = <MobileMenu visible={this.state.showMenu} />;
            containerClassName = String("MobileMenuContainer MobMenuNotDisplayed");
            if(this.state.showMenu){
                this.showHideMenu(false);
            }
        }

        let pageContainerAddClass = "";
        if(this.state.mobileMenuFirstClick){
            if(this.state.showMenu){
                pageContainerAddClass = "moveMenu";
            } else {
                pageContainerAddClass = "slideIn";
            }
        }


        return(
            <section>
                <section className={containerClassName}>
                    {menu}
                </section>
                    <section className={"pageContainer " + pageContainerAddClass + (this.state.loading ? "disabled" : "")} style={{position: this.state.showMenu ? "fixed": "relative"}}>
                        <Routes>
                            <Route path={ "/" } element={<LoginScreen {...this.props} setLoading={this.setLoading} showHideMenu={this.showHideMenu} showMenu={this.state.showMenu} screenWidth={this.state.screenWidth} />}/>
                            <Route path={ "/v7" } element={<LoginScreen {...this.props} setLoading={this.setLoading} showHideMenu={this.showHideMenu} showMenu={this.state.showMenu} screenWidth={this.state.screenWidth} />}/>
                            <Route path={ "/reset" } element={<ResetPasswordScreen {...this.props} setLoading={this.setLoading} showHideMenu={this.showHideMenu} showMenu={this.state.showMenu} screenWidth={this.state.screenWidth} />}/>
                        </Routes>

                        <section className={"spinner"}>
                            <ClipLoader
                                className={""}
                                size={50}
                                color={'#0667ba'}
                                loading={this.state.loading} />
                        </section>
                    </section>
            </section>
        )
    }
}

export interface SubPageProps {
    setLoading: Function;
    isLoading?: Function;
    showHideMenu: any;
    showMenu: boolean;
    history?: any;
    screenWidth: number;
}

export class LoginScreen extends React.Component<SubPageProps, {}>{
    render() {
        return(
            <section>
                <header role={"banner"}>
                    <NFCUHeader setLoading={this.props.setLoading} toggleMobileMenu={this.props.showHideMenu} menuOpen={this.props.showMenu} />
                </header>
                <ScrollToTopOnMount/>
                <section className={"bodySection"} role={"main"}>
                    <LoginPage setLoading={this.props.setLoading} screenWidth={this.props.screenWidth}/>
                </section>
                <footer role={"contentinfo"}>
                    <NFCUFooter />
                </footer>
            </section>
        );
    }
}

class ResetPasswordScreen extends React.Component<SubPageProps, {}>{
    render() {
        return(
           <section>
               <header role={"banner"}>
                   <NFCUHeader setLoading={this.props.setLoading} toggleMobileMenu={this.props.showHideMenu} menuOpen={this.props.showMenu}/>
               </header>
               <ScrollToTopOnMount />
               <section className={"bodySection"} role={"main"}>
                   <ResetPasswordForm setLoading={this.props.setLoading} showMenu={Boolean(this.props.showMenu)} />
               </section>
               <footer role={"contentinfo"}>
                   <NFCUFooter />
               </footer>
           </section>
        );
    }
}

class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}

export default AppContainer;
