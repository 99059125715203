import * as React from "react";

export interface Props {
    visible:boolean;
}

export class MobileMenu extends React.Component<Props, {}> {
    private ref = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(!prevProps.visible && this.props.visible){
            let element = this.ref.current;
            if(element !== null){
                element.focus();
            }
        }
        if(prevProps.visible && !this.props.visible){
            let element = this.ref.current;
            if(element !== null){
                if(document.activeElement === element){
                    element.blur();
                }
            }
        }
    }

    render() {

        return(
            <section style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                <section id="topOfMobileMenu" className="MobileMenuHeader" ref={this.ref} tabIndex={-1} aria-label={"Top of Menu"} style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                </section>
                <section className="MobileMenuContent" role={"none"}>
                    <ul style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                        <li className="MobLocations">
                             <a id="MobMenuLocations" href="https://www.navyfederal.org/branches-atms/index.php" rel="noopener noreferrer" style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                                 <span className={"nfcuicon icon-locations"} aria-hidden={true} />
                                 Locations
                             </a>
                        </li>
                        <li className="MobContactUs">
                            <a id="MobMenuContactUs" href="https://www.navyfederal.org/contact-us/" rel="noopener noreferrer" style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                                 <span className={"nfcuicon icon-home"} aria-hidden={true} />
                                 Contact Us
                            </a>
                        </li>
                        <li id="MobMenuRoutNum" className="MobRoutingNumber"  style={{ visibility: this.props.visible ? "visible" : "hidden" }}>
                            <p>Routing Number: <strong>256074974</strong></p>
                        </li>
                    </ul>
                </section>
            </section>

        );
    }
}