import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import AppContainer from "./AppContainer";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from 'history';
import { BrowserRouter } from "react-router-dom";
import axiosInstance from "./api";
import Recaptcha from "react-recaptcha";

export let history = createBrowserHistory();

const container:HTMLElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);

let context = document.querySelector('base')?.getAttribute('href') ?? '/'

root.render(
  <React.StrictMode>
      <BrowserRouter basename={context}>
          <AppContainer history={history}/>
      </BrowserRouter>
  </React.StrictMode>
);

let recaptchaInstance:any;
let recaptchaSiteKey:string = "";

const siteKey = async function() {
    await axiosInstance.get("/utils/sitekey")
            .then(response => {
                if(response != null) {
                    recaptchaSiteKey = response.data;
                }
            });
};

const executeCaptcha = function() {
    if(recaptchaInstance !== undefined){
        recaptchaInstance.execute();
    }
};

declare global{  interface Window { executeCaptcha: void  }  }
window.executeCaptcha = executeCaptcha();

const verifyRecaptcha = (token:string) => {
    axiosInstance.post("/utils/verify", token)
        .then(response => {
            if(!response.data){
                disableElement("username");
                disableElement("password");
                disableElement("signInButton");
            }
        })
        .catch(error => {
            disableElement("username");
            disableElement("password");
            disableElement("signInButton");
        });
};

const disableElement = (name:string) => {
    let element = document.getElementById(name);
    if(element !== null){
        element.setAttribute("disabled","true");
    }
};

let recaptcha:boolean = false;

const isRecaptcha = async function(){
    await axiosInstance.get("/utils/recaptcha")
        .then(response => {
            recaptcha = Boolean(response.data);
        });
};

const setRecaptcha = (value:any) => {
    recaptchaInstance = value;
};

const renderRecaptcha = async function(){
    await isRecaptcha();
    if(recaptcha) {
        let script = document.createElement("script");
        script.setAttribute("src", "https://www.google.com/recaptcha/api.js?render=explicit");
        script.toggleAttribute("async");
        script.toggleAttribute("defer");
        document.body.appendChild(script);

        if (document.getElementById("recaptcha") !== null) {
            await siteKey();
            let reCaptchaSiteKey:string = recaptchaSiteKey.length > 0 ? recaptchaSiteKey : "12345";
            const container:HTMLElement = document.getElementById("recaptcha") as HTMLElement;
            const root = ReactDOM.createRoot(container);
            root.render(
                <Recaptcha
                    ref={e => setRecaptcha(e)}
                    className={"loginRecaptchaClass"}
                    id={"loginRecaptcha"}
                    sitekey={reCaptchaSiteKey}
                    size={"invisible"}
                    badge={"bottomright"}
                    render={"explicit"}
                    onloadCallback={executeCaptcha}
                    verifyCallback={verifyRecaptcha}
                />
            );
        }
    }
};

renderRecaptcha();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
