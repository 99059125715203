import * as React from "react";
import { cancel } from '../Utils'

export interface Props {
    orangeOption?: string;
    grayOption?: string;
    blueOption?: string;
    blueFunction?: any;
    onContinue?: any;
    onCancel?: Function;
    continueDisabled?: boolean;
    className?: any;
    history?: any;
    setLoading: Function;
}

export class ButtonBar extends React.Component<Props, {}> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel(e: any) {
        if(this.props.onCancel) {
            this.props.onCancel(e);
        }
        else{
            if(this.props.setLoading){
                this.props.setLoading(true);
            }
            cancel();
        }
    }

    render() {
        let continueClassName = "button button-orange";
        if (this.props.continueDisabled) {
            continueClassName = continueClassName + " button-disabled";
        }
        let className = "buttonBar";
        if (this.props.className) {
            className = className + " " + this.props.className;
        }

        return(
            <section className={className}>
                {this.props.orangeOption ? <button type="button" className={continueClassName} onClick={this.props.onContinue} aria-disabled={this.props.continueDisabled} disabled={this.props.continueDisabled}>{this.props.orangeOption}</button> : ""}
                {this.props.grayOption ? <button type="button" className="button button-gray" onClick={this.onCancel}>{this.props.grayOption}</button> : ""}
                {this.props.blueOption ? <button type="button" className={"button button-blue"} onClick={this.props.blueFunction}>{this.props.blueOption}</button>: ""}
            </section>)
    }
}