import React from "react";
import parse from 'html-react-parser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface Props {
    expandLinkText: string;
    collapseLinkText: string;
    content: string;
}

export interface State {
    expanded: boolean;
}

export class ExpandableContainer extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = ({
            expanded: false
        });

        this.expandAndCollapse = this.expandAndCollapse.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(this.state.expanded !== prevState.expanded){
            if(this.state.expanded){
                document.getElementById("collapseLinkButton")!.focus();
            } else {
                document.getElementById("expandLinkButton")!.focus();
            }
        }
    }

    expandAndCollapse() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    handleKeyDown(e: { keyCode: number; preventDefault: () => void; }) {
        if(e.keyCode === 32 || e.keyCode === 13) {
            e.preventDefault();
            this.expandAndCollapse();
        }
    }

    render() {
        let containerClassName = this.state.expanded ? "expandableContainer expandedBackground" : "expandableContainer";

        return (
            <section className={containerClassName} onKeyDown={this.handleKeyDown}>
                {this.state.expanded ?
                    <section>
                        <button id={"collapseLinkButton"} className="collapseLink" onClick={this.expandAndCollapse} aria-expanded={this.state.expanded}>
                            <FontAwesomeIcon icon={"angle-double-up"} aria-hidden={true} focusable={false} /> {this.props.collapseLinkText}</button>
                        <section>{parse(this.props.content)}</section>
                    </section>
                    :
                    <button id={"expandLinkButton"} className="expandLink" onClick={this.expandAndCollapse} aria-expanded={this.state.expanded}>
                        <FontAwesomeIcon icon={"angle-double-down"} aria-hidden={true} focusable={false} /> {this.props.expandLinkText}</button>
                }
            </section>
        );
    }
}