import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBar } from "./ButtonBar";
import parse from 'html-react-parser';


export interface Props {
    setLoading: Function;
    id: string;
    show: boolean;
    onAccept: any;
    onDismiss: any;
    title: string;
    text: string;
    orangeText?: string;
    grayText?: string;
    history?: any;
    showSecondary?: boolean;
    showX?: boolean;
    timed?: boolean;
    timerSeconds?: number;
    timeRemaining?: number;
}

export interface State {
    timeRemaining: number;
    text: string;
    initialText:string;
}

export class Modal extends React.Component<Props, State> {

    public modalRef = React.createRef<any>();
    private ariaHiddenItems:Array<HTMLElement> = [];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            timeRemaining: 0,
            text: "",
            initialText: ""
        };

        this.onAccept = this.onAccept.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.focus = this.focus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.addAriaHidden = this.addAriaHidden.bind(this);
        this.removeFromElementsArray = this.removeFromElementsArray.bind(this);
        this.iterateOverTree = this.iterateOverTree.bind(this);
        this.removeAriaHidden = this.removeAriaHidden.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    componentDidMount() {
        if(this.props.timed){
            this.setState({
                text: this.props.text,
                timeRemaining: this.props.timeRemaining ? this.props.timeRemaining : 0,
                initialText: this.props.text
            });
        } else {
           this.setState({text: this.props.text});
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.props.show) {
            document.body.style.overflow = 'hidden';
        }
        if(!prevProps.show && this.props.show){
            this.addAriaHidden();
            this.focus();
        }
        if(prevProps.show && !this.props.show){
            this.removeAriaHidden();
        }
        if(prevProps.text !== this.props.text){
            this.setState({ text: this.props.text });
        }
        if(prevProps.timeRemaining !== this.props.timeRemaining){
            this.setState({ timeRemaining: this.props.timeRemaining ? this.props.timeRemaining : 0 });
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log(error);
        console.log(errorInfo);
    }

    focus() {
        let node = this.modalRef.current!;
        if(node !== null && node !== document.activeElement) {
            node.focus();
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].setAttribute("style","overflow: unset");
    }

    onAccept() {
        document.getElementsByTagName('body')[0].setAttribute("style","overflow: unset");
        this.props.onAccept();

    }

    onDismiss() {
        document.getElementsByTagName('body')[0].setAttribute("style","overflow: unset");
        this.props.onDismiss();
    }

    handleKeyDown(e: { keyCode: number; shiftKey: any; preventDefault: () => void; }) {
        let element = document.getElementById(this.props.id)!;
        let focusable = element.querySelectorAll('a[href]:not([disabled]), ' +
            'button:not([disabled]), ' +
            'textarea:not([disabled]), ' +
            'input[type="text"]:not([disabled]), ' +
            'input[type="radio"]:not([disabled]), ' +
            'input[type="checkbox"]:not([disabled]), ' +
            'select:not([disabled])');
        let first:HTMLElement = focusable[0] as HTMLElement;
        let last:HTMLElement = focusable[focusable.length - 1] as HTMLElement;

        if(this.props.show && e.keyCode === 9){
            if(e.shiftKey){
                if(document.activeElement === first){
                    e.preventDefault();
                    last.focus();
                }
            }else{
                if(document.activeElement === last){
                    e.preventDefault();
                    first.focus();
                }
            }
        }
    }

    addAriaHidden(){
        let allElements = document.querySelectorAll(
            'div, section, header, footer, span, a, ul, li, ol, p, nav, input, button, img, svg, defs, g, h1, h2, h3, h4, h5, h6, b, i, label, select, option, strong');
        let modal:HTMLElement = document.getElementById(this.props.id)!;
        let toBeHidden:Array<HTMLElement> = [];
        for(let i=0; i < allElements.length; i++){
            if(!allElements[i].hasAttribute("aria-hidden")){
                toBeHidden.push(allElements[i] as HTMLElement);
            }
        }
        let modalElements:Array<HTMLElement> = [];
        for(let i=0; i < modal.childElementCount; i++){
            modalElements.push(modal.children.item(i) as HTMLElement);

        }
        modalElements = this.iterateOverTree(modalElements);
        modalElements = this.removeFromElementsArray(modalElements);
        for(let i=0; i < modalElements.length; i++){
            for(let j=0; j < toBeHidden.length; j++){
                if(modalElements[i] === toBeHidden[j]){
                    toBeHidden.splice(j,1);
                }
            }
        }
        for(let i=0; i < toBeHidden.length; i++){
            toBeHidden[i].setAttribute("aria-hidden", "true");
            this.ariaHiddenItems.push(toBeHidden[i]);
        }
    }

    removeFromElementsArray(modalElements:Array<HTMLElement>):Array<HTMLElement> {
        modalElements.push(document.getElementById(this.props.id+"modalBorder")!);
        modalElements.push(document.getElementById(this.props.id+"overlay")!);
        modalElements.push(document.getElementById(this.props.id+"modal")!);
        modalElements.push(document.getElementById(this.props.id)!);
        modalElements.push(document.getElementById("app")!);
        if(this.props.timed){
            modalElements.push(document.getElementById("timeoutModalhidden-section")!);
            modalElements.push(document.getElementsByClassName("pageContainer")[0]! as HTMLElement);
            modalElements.push(document.getElementsByClassName("pageContainer")[0].parentElement as HTMLElement);
            modalElements.push(document.getElementById("root") as HTMLElement);
        } else {
            modalElements.push(document.getElementById(this.props.id+"modal")!.parentElement!);
            modalElements.push(document.getElementsByClassName("bodySection")[0]! as HTMLElement);
            modalElements.push(document.getElementsByClassName("bodySection")[0].parentElement as HTMLElement);
            modalElements.push(document.getElementsByClassName("pageContainer")[0]! as HTMLElement);
            modalElements.push(document.getElementsByClassName("pageContainer")[0].parentElement as HTMLElement);
            modalElements.push(document.getElementById("root") as HTMLElement);
        }
        return modalElements;
    }

    iterateOverTree(modalElements:Array<HTMLElement>):Array<HTMLElement> {

            for(let i=0; i < modalElements.length; i++){
                if(modalElements[i].hasChildNodes()){
                    let childNodesOne:Array<HTMLElement> = [];
                    try {
                        childNodesOne= Array.from(modalElements[i].children) as Array<HTMLElement>;
                    } catch(error){
                        //console.log("error Type: " + error.type + "\nerror static: " + error.static)
                    }
                    for(let j=0; j < childNodesOne.length; j++){
                        //one step down the tree
                        modalElements.push(childNodesOne[j] as HTMLElement);
                    }
                }
            }

        return modalElements;
    }

    removeAriaHidden(){
        if(this.ariaHiddenItems.length > 0){
            for(let i=0; i < this.ariaHiddenItems.length; i++){
                this.ariaHiddenItems[i].removeAttribute("aria-hidden");
            }
        }
        this.ariaHiddenItems = [];
    }

    handleFocus(e: FocusEvent | null){
        if(e !== null) { e.preventDefault(); }
    }

    render() {
        let buttonBar;
        if (this.props.showSecondary === false) {
            buttonBar = <ButtonBar setLoading={this.props.setLoading} orangeOption={this.props.orangeText ? this.props.orangeText : "No"}
                onContinue={this.onDismiss} className="modalButtons"/>
        } else {
            buttonBar = <ButtonBar setLoading={this.props.setLoading} orangeOption={this.props.orangeText ? this.props.orangeText : "No"}
                grayOption={this.props.grayText ? this.props.grayText : "Yes"}
                onContinue={this.onDismiss} onCancel={this.onAccept} className="modalButtons"/>
        }
        
        let xButton;
        if (this.props.showX) {
            xButton =
                <button className={"right"} aria-label={"close"} onKeyDown={this.handleKeyDown} onClick={this.onDismiss}>
                    <FontAwesomeIcon icon={"times-circle"} className="right" onClick={this.props.onDismiss}/>
                    <span className={"visually-hidden"}>Close</span>
                </button>
        }


        return (
            <section id={this.props.id+"modal"} className="modal" aria-hidden={!this.props.show} style={{display: this.props.show ? "block" : "none"}}>
                <section id={this.props.id+"overlay"} className="overlay"/>
                <section id={this.props.id+"modalBorder"} className="modalBorder">
                    <section id={this.props.id} className="modalContent" tabIndex={-1} ref={this.modalRef} onKeyDown={this.handleKeyDown}
                         role={"dialog"} aria-modal={true} aria-labelledby={this.props.id+"modalTitle"}
                         aria-describedby={this.props.timed ? this.props.id+"blank-section" : this.props.id+"modalText" }>
                        <section id={this.props.id + "modalTitle"} className="modalTitle">
                            <h4>{this.props.title}</h4>
                            {xButton}
                        </section>
                        <section id={this.props.id + "modalText"} className="contentText modalText"  role={"document"}>
                            <p>{parse(this.state.text)}</p>
                        </section>
                        <span id={this.props.id+"blank-section"} className={"visually-hidden"} role={"document"} />
                        {buttonBar}
                    </section>
                </section>

            </section>
        );
    }    
}