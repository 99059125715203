import React from "react";
import parse from 'html-react-parser';
import { ChecklistEntry } from "../loginTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface Props {
    negative?: boolean;
    optionsList: Array<ChecklistEntry>;
}

export class Checklist extends React.Component<Props, {}> {
    render() {
        let options = this.props.optionsList.map((option:ChecklistEntry) => {
            let color = "gray-check";
            let text = option.text;
            if(option.className === "checklist-disabled"){
                color = "gray-check";
                text = "<section><span class='visually-hidden'>requirement not met </span>" + option.text + "</section>";
            }

            if(option.className === "checklist-enabled"){
                color = "green-check";
                if(option.id === "passwordSpacesReq"){
                    text = String(`<section><span class='visually-hidden'>requirement met </span>${option.text}</section>`);
                } else {
                    text = String(`<section role='alert' aria-live='assertive' aria-atomic='true'><span class='visually-hidden'>requirement met </span>${option.text}</section>`);
                }
            }

            return (
                <>
                    <li id={option.id} className={option.className} key={option.id}>
                        <FontAwesomeIcon className={"mt-1 mr-2 " + color } icon={"check"} aria-hidden={true} focusable={false} /> { parse(text) }</li>
                </>
            );
        });

        return(
            <section className={"checklist"}>
                <ul>
                    {options}
                </ul>
            </section>
        )
    }
}