import React from "react";
import { Banner } from "../Components/Banner";
import * as Utils from "../Utils";
import { TextField } from "../Components/TextField";
import * as Constants from "../Constants";
import { SubmitButtonBar } from "../Components/SubmitButtonBar";
import { TextLink } from "../Components/TextLink";
import { ButtonBar } from "../Components/ButtonBar";
import BecomeAMemberImg from "../Assets/Images/img-BecomeAMember.jpg";
import { ReactComponent as EnrollCircleIcon } from "../Assets/Images/EnrollCircleIcon.svg";
import { ReactComponent as SecureCircleIcon } from "../Assets/Images/SecureCircleIcon.svg";
import { ReactComponent as NeedHelpCircleIcon } from "../Assets/Images/NeedHelpCircleIcon.svg";
import { ErrorBannerAttributes, textFieldAttribues } from "../loginTypes";
import axiosInstance from "../api";
import Cookies from "js-cookie";
import * as Analytics from '../Common/Analytics';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface PageProps {
    setLoading: Function;
    history?: any;
    location?: any;
    screenWidth: number;
}

interface PageState {
    showBanner:boolean;
    signInHelp:string;
    becomeAMember:string;
    enrollDigitalBanking:string;
    formUrl:string;
    targetUrl:string;
    username:textFieldAttribues;
    password:textFieldAttribues;
    signInButton:boolean;
    errorBanners:Array<ErrorBannerAttributes>;
}

export class LoginPage extends React.Component<PageProps, PageState> {

    private axios = axiosInstance;

    constructor(props: PageProps | Readonly<PageProps>){
        super(props);
        this.state = {
            showBanner: false,
            signInHelp: "",
            becomeAMember: "",
            enrollDigitalBanking: "",
            formUrl: "",
            targetUrl: "",
            username: { value: "" },
            password: { value: "", visible: false },
            signInButton: false,
            errorBanners: new Array<ErrorBannerAttributes>(),
        };

        this.clearAllBanners = this.clearAllBanners.bind(this);
        this.closeBanner = this.closeBanner.bind(this);
        this.getEndpoints = this.getEndpoints.bind(this);
        this.becomeAMember = this.becomeAMember.bind(this);
        this.becomeAMemberLearnMore = this.becomeAMemberLearnMore.bind(this);
        this.updateState = this.updateState.bind(this);
        this.signInButton = this.signInButton.bind(this);
        this.enableOnChange = this.enableOnChange.bind(this);
        this.isFieldValueValid = this.isFieldValueValid.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.errorBanners = this.errorBanners.bind(this);
        this.onSubmitListener = this.onSubmitListener.bind(this);
        this.checkBannersValues = this.checkBannersValues.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
    }

    componentDidMount() {
        Analytics.setPageName();
        this.getEndpoints();
        this.errorBanners();
        this.onSubmitListener();
        Utils.setAACookie();
    }

    componentDidUpdate(prevProps: Readonly<PageProps>, prevState: Readonly<PageState>): void {

    }

    async clearAllBanners(){
        this.props.setLoading(true);
        let names:String[] = this.state.errorBanners.map( value => { return value.name });
        await this.axios.post("/utils/errors", names)
            .then(response => {
                    //good job
                },
                response =>{
                    throw Error(response);
            })
            .catch(error => {
                if(error != null){
                    console.log(error);
                }
            });
    }

    async closeBanner(name?:string) {
        if(name !== null){
            let errorBanners:Array<ErrorBannerAttributes> = this.state.errorBanners;
            for(let error of errorBanners){
                if(error.name === name){
                    let index = errorBanners.indexOf(error);
                    errorBanners.splice(index,1);
                    let names:String[] = [];
                    names.push(name);
                    await this.closeBannerCall(names);
                }
            }
            this.setState({ errorBanners: errorBanners });
        }
    }

    async closeBannerCall(name:String[]){
        await this.axios.post("/utils/errors", name)
            .then(response => {
                    //good job
                },
                response =>{
                    throw Error(response);
                })
            .catch(error => {
                //decide if you want to do anything with this error
            });
    }

    getEndpoints(){
        this.axios.get('/utils/endpoints')
            .then(response => {
                if(response.data != null){
                    this.setState({
                        signInHelp: response.data.signInHelp,
                        becomeAMember: response.data.becomeAMember,
                        enrollDigitalBanking: response.data.enrollDigitalBanking,
                        formUrl: response.data.formUrl,
                        targetUrl: response.data.targetUrl
                    });
                }
            })
            .catch(error => {

            })
    }

    becomeAMember(e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement> | null): void{
        if(e === null){
            return;
        } else {
            Utils.redirect(this.state.becomeAMember);
        }
    }

    becomeAMemberLearnMore(e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement> | null): void {
        if (e === null) {
            return;
        } else {
            Utils.redirect(Constants.becomeAMemberLearnMore);
        }
    }

    // Update state on change
    updateState(field: string | number, value: any) {
        let stateCopy: React.ComponentState = { ...this.state };
        stateCopy[field].value = value;
        this.setState(stateCopy);
    }

    signInButton(changeValue?:boolean):boolean{
        if(changeValue !== undefined){
            this.setState({
                signInButton: changeValue
            })
        }
        return this.state.signInButton;
    }

    enableOnChange(e: { currentTarget: { id: any; }; }) {
        let stateCopy: React.ComponentState = { ...this.state };
        const fieldName = e.currentTarget.id;
        const fieldValue = stateCopy[fieldName].value;

        let errors = 0;
        // Find errors in field entries and enable/disable continue button
        if (!this.isFieldValueValid(fieldName, fieldValue)) {
            errors++;
        }
        for (let field in stateCopy) {
            if(stateCopy.hasOwnProperty(field)){
                if ((stateCopy[field].hasOwnProperty('showInline') && stateCopy[field].showInline !== undefined && stateCopy[field].showInline)
                    || (stateCopy[field].hasOwnProperty('value') && stateCopy[field].value === "")) {
                    errors++;
                }
            }
        }

        stateCopy["signInButton"] = !(errors > 0);

        this.setState(stateCopy);
    }

    isFieldValueValid(fieldName: string, fieldValue: string) {
        if (fieldName === "username") {
            if (fieldValue.length === 0 ) {
                return false;
            }
        }
        if (fieldName === "password") {
            if (fieldValue.length < 1) {
                return false;
            }
        }
        return true;
    }

    handleKeyDown(e:KeyboardEvent){
        if(e === null){
            return;
        }
        if(e.key === "Enter" && this.state.signInButton){
            let form = document.getElementsByTagName("form")[0]!;
            form.submit();
        }
    }

    async errorBanners(){
        let errorBannerList:Array<string> = await this.axios.get("/utils/errors")
            .then( response => {
                    if(response.data != null){
                        return response.data;
                    } else {
                        return new Array<string>();
                    }
                },
                () => {
                    return new Array<string>();
            });
        //errorBannerList.push("USER_LOCKED_OUT");
        let errorBanners:Set<ErrorBannerAttributes> = new Set<ErrorBannerAttributes>();
        if(errorBannerList.length > 0){
            if(errorBannerList.length > 1){
                for(let error of errorBannerList){
                    let banner:ErrorBannerAttributes | null = this.checkBannersValues(error);
                    if(banner !== null){
                        if(banner.name === "PASSWORD_CHANGE_COMPLETE"){
                            let specialSet = new Set<ErrorBannerAttributes>();
                            specialSet.add(banner);
                            errorBanners = specialSet;
                            break;
                        }
                        if(banner.name === "PASSWORD_CHANGE_COMPLETE"){
                            let specialSet = new Set<ErrorBannerAttributes>();
                            specialSet.add(banner);
                            errorBanners = specialSet;
                            break;
                        }

                        errorBanners.add(banner);
                    }
                }
            } else {
                let banner:ErrorBannerAttributes | null = this.checkBannersValues(errorBannerList[0]);
                if(banner !== null){
                    errorBanners.add(banner);
                }
            }
        }

        if(errorBanners.size > 0){
            let errors:Array<ErrorBannerAttributes> = new Array<ErrorBannerAttributes>();
            errorBanners.forEach(error => {
                errors.push(error);
            });
            this.setState({ errorBanners: errors });
        }
    }

    onSubmitListener(){
        document.getElementsByTagName("form")[0].addEventListener("submit", () => this.clearAllBanners());
    }

    checkBannersValues(value:string):ErrorBannerAttributes | null{
        let returnValue:ErrorBannerAttributes | null = null;
        Constants.Banners.forEach(object => {
           if(object.name === value){
               returnValue = object;
           }
        });
        return returnValue;
    }

    togglePassword(e:React.KeyboardEvent | React.MouseEvent ) {
        if (e.currentTarget.id === "passwordIcon") {
            this.setState({
                password: {
                    visible: !this.state.password.visible,
                    value: this.state.password.value !== undefined ? this.state.password.value : ""
                }
            });
        }
    }
    
    render() {
        let banner:any = <section/>;

        if(this.state.errorBanners.length > 0){
            let banners:Array<object> = this.state.errorBanners.map( (error,key) => {
                let name:string = error.name;
                let bannerText:string = Utils.sanitizeBannerText(error.text);
                let bannerInfoText:string = Utils.sanitizeInfoBannerText(error.infoText);
                if(name === "USER_LOCKED_OUT"){
                    return ( <Banner key={key} text={bannerText} infoText={bannerInfoText} severity={error.severity} name={name} anchor={{url: this.state.signInHelp, text: "reset your password"}} onClose={() => this.closeBanner(name)} /> )
                } else {
                    return ( <Banner key={key} text={bannerText} infoText={bannerInfoText} severity={error.severity} name={name} onClose={() => this.closeBanner(name)} />);
                }
            });
            if(banners.length > 0){
                banner = banners;
            }
        }

        return (
            <section className={"loginPageContent"}>
                <section className={"topLogin"}>
                    <section className={"topLoginContainer"}>
                        <h1>Welcome to Digital Banking</h1>
                            <section className={"bannerContainer"}>
                                <section id={"bannerAlertLiveSection"} className={"visually-hidden"} aria-live={"assertive"} aria-atomic={true} />
                                {banner}
                            </section>
                        <section className={"credentialFormPanel"}>
                            <section className={"credentialFormBorder"}>
                                <section className={"credentialTitleSection"}>
                                    <FontAwesomeIcon icon={"lock"} id={"lockIcon"} aria-hidden={true} focusable={false}/>
                                    <h2>Sign In</h2>
                                    <section className={"titleSeparator"} />
                                </section>
                                <form id={"loginForm"} className={"credentialForm"} name={"Login"} method={"POST"} action={this.state.formUrl.toString()} autoComplete={"off"}>
                                    <section className={"textFieldsContainer"}>
                                        <TextField
                                            id={"username"}
                                            type={"text"}
                                            inputMode={"text"}
                                            label={"Username"}
                                            tooltip={{label: "username help",content: Constants.usernameToolTip, icon:true}}
                                            value={this.state.username.value}
                                            name={"USER"}
                                            // eslint-disable-next-line
                                            allowedChars={"a-zA-Z0-9_;/$@#.'%+!~{}`:,|[?\\\]\\\^\\\-"}
                                            maxLength={32}
                                            updateState={this.updateState}
                                            enableOnChange={this.enableOnChange}
                                        />
                                        <TextField id={"password"} label={"Password"} guideText={""}
                                           type={this.state.password.visible ? "text" : "password" }
                                           value={this.state.password.value}
                                           minLength={1}
                                           maxLength={32}
                                           name={"PASSWORD"}
                                           // eslint-disable-next-line
                                           allowedChars={"a-zA-Z0-9_;/$@#.'%+!~{}`:,|[?\\\]\\\^\\\-"}
                                           updateState={this.updateState}
                                           enableOnChange={this.enableOnChange}
                                           onKeyDown={this.handleKeyDown}
                                           togglePassword={this.togglePassword}
                                           passwordField={true}
                                           showPassword={this.state.password.visible}
                                        />
                                        {this.props.screenWidth < 620 ?
                                            <React.Fragment>
                                                <TextLink
                                                    id={"signInHelpLink"}
                                                    text={"Sign In Help"}
                                                    url={this.state.signInHelp.toString()}
                                                    newWindow={false}
                                                />
                                                <SubmitButtonBar
                                                    id={"credentialFormButtons"}
                                                    orangeOption={"Sign In"}
                                                    orangeId={"signInButton"}
                                                    setLoading={this.props.setLoading}
                                                    continueDisabled={!this.signInButton()}
                                                    reCaptchaEnabled={true}
                                                />
                                            </React.Fragment>
                                        : null}
                                    </section>
                                    {this.props.screenWidth > 619 && this.props.screenWidth <= 692 ?
                                        <section id={"loginFlexContainer"}>
                                            <SubmitButtonBar
                                                id={"credentialFormButtons"}
                                                orangeOption={"Sign In"}
                                                orangeId={"signInButton"}
                                                setLoading={this.props.setLoading}
                                                continueDisabled={!this.signInButton()}
                                                reCaptchaEnabled={true}
                                            />
                                            <TextLink
                                                id={"signInHelpLink"}
                                                text={"Sign In Help"}
                                                url={this.state.signInHelp.toString()}
                                                newWindow={false}
                                            />
                                        </section>
                                        : null}
                                    {this.props.screenWidth > 692 ?
                                        <React.Fragment>
                                        <SubmitButtonBar
                                            id={"credentialFormButtons"}
                                            orangeOption={"Sign In"}
                                            orangeId={"signInButton"}
                                            setLoading={this.props.setLoading}
                                            continueDisabled={!this.signInButton()}
                                            reCaptchaEnabled={true}
                                        />
                                        <TextLink
                                            id={"signInHelpLink"}
                                            text={"Sign In Help"}
                                            url={this.state.signInHelp.toString()}
                                            newWindow={false}
                                        />
                                        </React.Fragment>
                                        : null}
                                    <input type="hidden" name="SMENC" value="ISO-8859-1" />
                                    <input type="hidden" name="SMLOCALE" value="US-EN" />
                                    <input type="hidden" name="smquerydata" value="" />
                                    <input type="hidden" name="smauthreason" value={`${Cookies.get("SMAUTHREASON") !== undefined ? Cookies.get("SMAUTHREASON") : ""}`} />
                                    <input type="hidden" name="target" value={this.state.targetUrl} />
                                    <input type="hidden" name="smagentname" value={`${Cookies.get("SMAGENTNAME") !== undefined ? Cookies.get("SMAGENTNAME") : ""}`} />
                                    <input type="hidden" name="postpreservationdata" value="" />
                                    <input type="hidden" name="akamaiCookieValue" value={`${Cookies.get("dc") !== undefined ? Cookies.get("dc") : "w"}`} id="akamaiCookieValue" />
                                    <input type="hidden" name="akamaiCookieName" value={"dc"} id="akamaiCookieName" />
                                </form>
                            </section>
                        </section>
                    </section>
                </section>
                <section className={"middleLogin"}>
                    <section className={"middleLoginContainer"}>
                        <section className={"middleLeftSide"}>
                            <section className={"middleLeftSideContent"}>
                                <h3 id={"notAMemberHeading"}>Not a Navy Federal Member?</h3>
                                <p>Join now and enjoy the support and great service of a credit union that puts your needs first.</p>
                                <ButtonBar setLoading={this.props.setLoading}
                                           orangeOption={"Become a Member"} onContinue={this.becomeAMember}
                                           blueOption={"Learn More"} blueFunction={this.becomeAMemberLearnMore}/>
                            </section>
                        </section>
                        <section className={"middleRightSide"}>
                            <img src={BecomeAMemberImg} alt={""} aria-hidden={"true"}/>
                        </section>
                    </section>
                </section>
                <section className={"bottomLogin"}>
                    <section className={"bottomLoginContainer"}>
                        <section className={"bottomContentBox"}>
                            <section className={"bottomContentBoxCircle"}>
                                <EnrollCircleIcon title={""} focusable={false} aria-hidden={true} />
                            </section>
                            <h4>Don't have online access?</h4>
                            <TextLink id={"enrollBoxLink"} text={"Enroll in digital banking"} unicode={187} url={this.state.enrollDigitalBanking.toString()} newWindow={false}/>
                        </section>
                        <section className={"bottomContentBox"}>
                            <section className={"bottomContentBoxCircle"}>
                                <SecureCircleIcon title={""} className={"specialAdjustment"} focusable={false} aria-hidden={true} />
                            </section>
                            <h4 id={"learnMoreHeading"}>Find out more about secure digital banking</h4>
                            <TextLink id={"learnMoreBoxLink"} text={"Learn More"} unicode={187} url={"https://www.navyfederal.org/services/security/digital-security.php"} ariaDescribedby={"learnMoreHeading"} newWindow={false}/>
                        </section>
                        <section className={"bottomContentBox"}>
                            <section className={"bottomContentBoxCircle"}>
                                <NeedHelpCircleIcon title={""} focusable={false} aria-hidden={true} />
                            </section>
                            <h4 id={"needHelpHeading"}>Need help?</h4>
                            <TextLink id={"contactUsBoxLink"} text={"Contact us"} unicode={187} url={"https://www.navyfederal.org/contact-us/"} ariaDescribedby={"needHelpHeading"} newWindow={false}/>
                        </section>
                    </section>
                </section>
            </section>
        );
    }
}