import React from "react";

export interface Props {
    id?:string;
    url: string;
    text: string;
    icon?: string;
    unicode?: number;
    ariaDescribedby?: string;
    newWindow?: boolean;
}

export class TextLink extends React.Component<Props, {}> {
    render() {
        return (
            <section id={this.props.id} className="contentText textLink">
                <a href={this.props.url} target={this.props.newWindow ? "_blank" : "_self"} rel={'noopener noreferrer'} aria-describedby={this.props.ariaDescribedby}>
                    {this.props.icon ?
                        <div className="icon">
                            <img src={String(this.props.icon)} alt="Question mark icon" />
                        </div>
                        : null}
                    <section className="text">
                        {this.props.text}
                        {this.props.unicode ? <span aria-hidden={"true"}>{" " + String.fromCharCode(this.props.unicode)}</span> : null}
                    </section>
                </a>
            </section>
        )
    }
}

