import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToolTip } from "./ToolTip";
import { toolTipAttributes } from "../loginTypes";

export interface TextFieldProps {
    children?: React.ReactNode;
    id: string;
    type: string;
    label: string;
    inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
    pattern?: string;
    value?: string;
    name?: string;
    minLength?: number;
    maxLength?: number;
    optional?: boolean;
    disabled?: boolean;
    allowedChars?: string;
    ariaLabel?: string;
    guideText?: string;
    inlineErrors?: any;
    updateState?: any;
    changeInlineError?: any;
    onKeyDown?: any;
    onChange?: any;
    onFocus?: any;
    onPaste?: any;
    onBlur?: any;
    className?: string;
    enableOnChange?: any;
    passwordField?: boolean;
    showPassword?: any;
    togglePassword?: any;
    tabIndex?: number;
    tooltip?: toolTipAttributes;
}

export class TextField extends React.Component<TextFieldProps, {}> {
    constructor(props: TextFieldProps | Readonly<TextFieldProps>) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handlePasswordKeyDown = this.handlePasswordKeyDown.bind(this);
    }

    onChange(e: { currentTarget: { value: any; }; }) {
        this.preventDoubleSpace(e);

        // Most likely, onChange passed in as prop but if not, disallow special characters if they were passed in
        if (this.props.onChange) {
            this.props.onChange(e);
        } else if (this.props.allowedChars) {
            this.disallowSpecialChars(e);
        } else {
            let currentValue = e.currentTarget.value;
            // Uniform Apostrophes
            let replaceCurly = new RegExp("[’]", "g");
            currentValue = currentValue.replace(replaceCurly, "'");
            this.props.updateState(this.props.id, currentValue);
        }
        if (this.props.changeInlineError) {
            this.props.changeInlineError(this.props.id, false);
        }
        if (this.props.enableOnChange) {
            this.props.enableOnChange(e);
        }
    }

    // Bug for Macs with double space preventing a period
    preventDoubleSpace(e: { currentTarget: any; preventDefault?: any; }) {
        e.preventDefault();
        let currentValue = e.currentTarget.value;
        if (currentValue.length >= 2) {
            let lastTyped = currentValue.substring(currentValue.length - 2, currentValue.length - 1);
            let newTyped = currentValue.substring(currentValue.length - 1, currentValue.length);
            if (lastTyped === " " && lastTyped === newTyped) {
                currentValue = currentValue.substring(0, currentValue.length - 1);
                this.props.updateState(this.props.id, currentValue);
            }
        }
    }

    // Only allow characters passed in as property, else standard alphanumerics
    disallowSpecialChars(e: { currentTarget: any; }) {
        let currentValue = e.currentTarget.value;

        // Filter Out Invalid Chars
        let replaceInvalid = this.props.allowedChars ? "[^" + this.props.allowedChars + "]" : "[]";
        let replaceExpression = new RegExp(replaceInvalid, "g");
        currentValue = currentValue.replace(replaceExpression, '');

        // Uniform Apostrophes
        let replaceCurly = new RegExp("[’]", "g");
        currentValue = currentValue.replace(replaceCurly, "'");

        this.props.updateState(this.props.id, currentValue);
    }

    // Handle formatting of pasted strings
    handlePaste(e: React.ClipboardEvent<HTMLInputElement> | null): void {
        if(e != null && e.currentTarget){
            e.preventDefault();
            let field:HTMLInputElement = e.currentTarget as HTMLInputElement;
            let fieldDisabled:boolean = field.hasAttribute("disabled");
            if (this.props.onPaste) {
                this.props.onPaste(e);
            } else if (this.props.allowedChars && !fieldDisabled && field.selectionStart != null && field.selectionEnd != null) {
                let currentValue = field.value;

                // Replace Invalid Chars in Pasted String
                let pastedValue = "";
                if(e.clipboardData){
                    pastedValue = e.clipboardData.getData('Text');
                }

                // Uniform Apostrophes
                const replaceCurly = new RegExp("[’]", "g");
                pastedValue = pastedValue.replace(replaceCurly, "'");

                // If part of the input string selected, replace that with new pasted text
                if (field.selectionStart > 0 && field.selectionEnd < currentValue.length) {
                    currentValue = [currentValue.substring(0, field.selectionStart), pastedValue, currentValue.substring(field.selectionEnd)].join('');
                } else if (field.selectionStart || field.selectionEnd) {
                    if (field.selectionStart > 0) {
                        currentValue = currentValue.substring(0, field.selectionStart) + pastedValue;
                    } else {
                        currentValue = pastedValue + currentValue.substring(field.selectionEnd);
                    }
                } else {
                    currentValue = currentValue + pastedValue;
                }

                // If too long, trim down to maxLength of field
                let replace = this.props.allowedChars ? "[^" + this.props.allowedChars + "]" : "[]";
                let expression = new RegExp(replace, "g");
                let match = currentValue.match(expression);

                if (currentValue.length > field.maxLength || match !== null) {
                    currentValue = "";
                    this.setState({ previousInput: "" });
                    if (this.props.changeInlineError) {
                        this.props.changeInlineError(this.props.id, true);
                    }
                }

                this.props.updateState(this.props.id, currentValue);
                if (this.props.changeInlineError) {
                    this.props.changeInlineError(this.props.id, false);
                }
                if (this.props.enableOnChange) {
                    this.props.enableOnChange(e);
                }
            }
        }
    }

    handlePasswordKeyDown(e: { keyCode: number; preventDefault: () => void; ctrlKey: boolean; altKey: boolean; }) {
        //Windows with JAWS and NVDA
        if(e.keyCode === 32 || e.keyCode === 13) {
            e.preventDefault();
            this.props.togglePassword(e);
        }
        //Safari with VoiceOver
        if(e.ctrlKey && e.altKey && e.keyCode === 32){
            e.preventDefault();
            this.props.togglePassword(e);
        }
    }

    render() {
        // Determine how to handle paste - either function passed in, restricted chars passed in, or null
        let handlePasteFunc = this.props.onPaste ? this.props.onPaste : null;
        if (handlePasteFunc === null && this.props.allowedChars) {
            handlePasteFunc = this.handlePaste;
        }

        let classNameString = this.props.className ? "textField " + this.props.className : "textField";
        let optionalLabel = this.props.optional ? "(Optional)" : "";

        let inlineErrors = this.props.inlineErrors ? this.props.inlineErrors.map((errorMsg: React.ReactNode, i: number) => {
            let errorText = errorMsg as String;
            let returnValue;
            if(errorText.length > 0){
                returnValue = <React.Fragment key={i}><span className={"visually-hidden"}>invalid entry</span><p className="inlineError" id={this.props.id + "inlineError" + i}>{errorMsg}</p></React.Fragment>
            }
            return returnValue;
        })
            : null;

        let inlineIdList:string = "";
        if(this.props.inlineErrors){
            if(this.props.inlineErrors.length > 0){
                for(let i=0; i < this.props.inlineErrors.length; i++){
                    if(i === this.props.inlineErrors.length - 1){
                        inlineIdList += this.props.id + "inlineError" + i;
                    } else {
                        inlineIdList += this.props.id + "inlineError" + i + " ";
                    }
                }
            }
        }

        let inlineEnabled = (this.props.inlineErrors && this.props.inlineErrors.length > 0 && this.props.inlineErrors[0] !== "");

        return (
            <section className={classNameString}>
                <label htmlFor={this.props.id}>{this.props.label} <span className="optional">{optionalLabel}</span></label>
                {this.props.tooltip ? <ToolTip content={this.props.tooltip.content} label={this.props.tooltip.label} icon={this.props.tooltip.icon}/> : null }
                <span className={"textfieldInputContainer"}>
                    <input type={this.props.type}
                        inputMode={this.props.inputMode}
                        disabled={this.props.disabled}
                        pattern={this.props.pattern}
                        tabIndex={this.props.tabIndex}
                        id={this.props.id}
                        value={this.props.value}
                        name={this.props.name}
                        minLength={this.props.minLength}
                        maxLength={this.props.maxLength}
                        onKeyDown={this.props.onKeyDown}
                        onChange={this.onChange}
                        onPaste={handlePasteFunc}
                        onFocus={this.props.onFocus}
                        onBlur={this.props.onBlur}
                        className={inlineEnabled ? "error" : ""}
                        aria-invalid={inlineEnabled}
                        autoComplete="off"
                        aria-describedby={this.props.id + "GuideText" + (inlineIdList ? " " + inlineIdList: "")} />
                    {this.props.passwordField ?
                        <button type={"button"} id={this.props.id + "Icon"} aria-labelledby={this.props.id+"IconDesc"} aria-pressed={this.props.showPassword} className={"transparentButton passwordIcon"} onClick={this.props.togglePassword} onKeyDown={this.handlePasswordKeyDown}>
                            <FontAwesomeIcon icon={this.props.showPassword ? "eye-slash" : "eye" } aria-hidden={true} focusable={false} />
                            <span id={this.props.id+"IconDesc"} className={"visually-hidden"}>show password</span>
                        </button>
                        : null
                    }
                    {this.props.guideText ? <p id={this.props.id + "GuideText"} className="guideText" aria-label={this.props.ariaLabel ? this.props.ariaLabel + " " + this.props.guideText : this.props.guideText}>{this.props.guideText}</p> : null}
                </span>
                <span aria-live={"assertive"} aria-atomic={true} className={"inlineErrorContainer"}>
                    {inlineErrors}
                </span>
            </section>
        )
    }
}