import React from "react";
import { ReactComponent as FooterLogoImg } from '../Assets/Images/logo-NFCU-footer.svg';
import { ReactComponent as Ehl } from '../Assets/Images/logo-EHL.svg';
import { ReactComponent as Ncua } from '../Assets/Images/logo-NCUA.svg';

export interface Props {
}

export class NFCUFooter extends React.Component<Props, {}> {

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.navigateToNFCUHome = this.navigateToNFCUHome.bind(this);
    }

    navigateToNFCUHome() {
        window.location.href = 'https://www.navyfederal.org/';
    }

    render() {
        let year = new Date().getFullYear();

        return (
            <section className="footerBackground">
                <section className="footerContainer">
                    <section className="footerLogo">
                        <button role={"link"} className={"transparentButton"} aria-labelledby={"footerNFCULogo"} onClick={this.navigateToNFCUHome}>
                            <FooterLogoImg role={"link"} className={"Footer-Logo"} focusable={false} />
                            <span id={"footerNFCULogo"} className={"visually-hidden"} tabIndex={-1}>Navy Federal Credit Union logo, home page</span>
                        </button>
                    </section>
                    <section className="footerContent">
                        <section className="memberServices">
                            <p>24/7 Member Services: <strong>1-888-842-6328</strong></p><p className="separator" aria-hidden={true}> | </p><p>Routing Number: <strong>256074974</strong></p>
                        </section>
                        <section className="lineBreak"/>
                        <section className="navLinks" role={"navigation"}>
                            <ul>
                                <li>
                                    <a href="https://www.navyfederal.org/about/about.php">
                                        <p>About Us</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/contact-us/">
                                        <p>Contact Us</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/privacy/online.php">
                                        <p>Privacy</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/security/">
                                        <p>Security</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/accessibility.php">
                                        <p>Accessibility</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/support/index.php">
                                        <p>Browser Support</p>
                                    </a>
                                </li>
                            </ul>
                        </section>
                        <section className="copyRight">
                            <p>© {year} Navy Federal Credit Union. </p>
                            <p className="light">All rights reserved.</p>
                        </section>

                        <section className="navLinks2">
                            <ul>
                                <li>
                                    <a href="https://www.navyfederal.org/pdf/ebrochures/1116e.pdf">
                                        <Ehl className={"EHL-svg"} title={""} aria-hidden={true} />
                                        <p>Federally Insured by NCUA</p>
                                    </a>
                                    <p className="spacing" aria-hidden={true}> | </p>
                                </li>
                                <li>
                                    <a href="https://www.navyfederal.org/pdf/ebrochures/3035_EHL_Poster.pdf">
                                        <Ncua className={"NCUA-svg"} title={""} aria-hidden={true} />
                                        <p>Equal Housing Lender</p>
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <section className="apy">
                            <p className="messageText">APY = Annual Percentage Yield  |  APR = Annual Percentage Rate</p>
                        </section>

                        <section className="rates">
                            <p className="messageText">+Rates are based on an evaluation of credit history, so your rate may differ.</p>
                        </section>

                        <section className="rates2">
                            <p className="messageText">++Rates are variable, and based on an evaluation of credit histroy, so your rate may differ.</p>
                        </section>

                        <section className="messageRates">
                            <p className="messageText">*Message and data rates may apply. Terms and Conditions are available.</p>
                        </section>

                        <section className="disclosure">
                            <p className="messageText">iPhone®, iPad® and iPod touch® are trademarks of Apple Inc. App StoreSM is a service mark of Apple Inc. AndroidTM and Google PlayTM are trademarks of Google Inc. Image used for representational purposes only; do not imply government endorsement.</p>
                        </section>

                        <section className="webPolicy">
                            <p className="messageText">Navy Federal uses small text files called cookies to collect anonymous Web site traffic data. This information helps improve our Web services. Our cookies do not collect or store any personally identifiable information.</p>
                        </section>

                        <section className={"reCaptchaPolicies"}>
                            <p className={"messageText"}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                        </section>
                    </section>

                </section>
            </section>
        );
    }
}